import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GameCategoryWrapper from "../components/gameCategoryWrapper"
function AllCategories() {
  const { allGamesDataJson } = useStaticQuery(
    graphql`
      query allCategoryDataQuery {
        allGamesDataJson {
          group(field: category) {
            edges {
              node {
                category
                name
                id
                image
                link
              }
            }
          }
        }
      }
    `
  )
  return (
    <div>
      {console.log(allGamesDataJson)}
      {allGamesDataJson.group.map((n, i) => {
        const categoryName = n.edges[0].node.category.toLowerCase()
        return (
          <GameCategoryWrapper
            viewAll={false}
            key={i}
            category={categoryName}
            data={n.edges}
            gameLink={categoryName}
          />
        )
      })}
    </div>
  )
}

export default AllCategories
